import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "gatsby";
import favicon from '../assets/favicon.ico';
import errorimage from "../assets/404.svg";
import "../css/404.css";
import "../css/index.css";

const ErrorPage = () => {
  return (
    <div className="errorWrapper">
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="noindex" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="errorContent">
        <div className="errorimage">
          <img src={errorimage} alt="404" />
        </div>
        <div className="errortext">
          <p className="errortext_top">404</p>
          <p className="errortext_middle">UH OH! You're lost.</p>
          <p className="errortext_bottom">
            The page you are looking for does not exist. How you got here is a
            mystery.
          </p>
        </div>
      </div>
      <div className="buttonWrapper">
        <Link className="back" to="/">
          Back to earth!
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
